import {
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { ReactNode, useEffect, useRef, useState } from "react";

import { Button, ButtonProps } from "./button";
import { Dropdown, DropdownPartProps } from "./dropdown";

export type ContextMenuProps = {
  id?: string;
  button: Omit<ButtonProps, "onClick">;
  buttonOverride?: ReactNode;
  items: (Omit<DropdownPartProps, "rightIcon" | "value" | "onMouseEnter"> & {
    subMenu?: DropdownPartProps[];
    keepOpen?: boolean;
  })[];
  className?: string;
  placement?: Placement;
  fallbackPlacements?: Placement[];
  offsetBy?: number;
};

export const ContextMenu = ({
  id,
  items,
  className,
  button,
  buttonOverride,
  placement = "bottom",
  fallbackPlacements,
  offsetBy = 0,
}: ContextMenuProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenu, setSubMenu] = useState<DropdownPartProps[] | null>(null);
  const menu = useFloating({
    placement,
    open: menuOpen,
    onOpenChange: setMenuOpen,
    middleware: [
      shift({ padding: 10 }),
      flip({ fallbackPlacements: fallbackPlacements || ["bottom", "top"] }),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.max(0, availableHeight) - 10}px`,
          });
        },
      }),
      offset(offsetBy),
    ],
    whileElementsMounted: autoUpdate,
  });
  const subMenuFloating = useFloating({
    placement: "right-start",
    middleware: [offset(), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
  const menuInteractions = useInteractions([
    useDismiss(menu.context),
    useClick(menu.context),
  ]);

  const subMenuRefs = useRef<(HTMLElement | null)[]>([]);

  useEffect(() => {
    if (!menuOpen) {
      setSubMenu(null);
    }
  }, [menuOpen]);

  return (
    <div
      className={className}
      data-testid={`${id ? `${id}-` : ""}context-menu`}
      ref={menu.refs.setReference}
      {...menuInteractions.getReferenceProps()}
    >
      {buttonOverride ? (
        <button type="button" onClick={() => setMenuOpen((v) => !v)}>
          {buttonOverride}
        </button>
      ) : (
        <Button {...button} onClick={() => setMenuOpen((v) => !v)} />
      )}
      {menuOpen && (
        <div
          className="z-20 flex"
          ref={menu.refs.setFloating}
          style={menu.floatingStyles}
          {...menuInteractions.getFloatingProps()}
          data-testid={`${id ? `${id}-` : ""}context-menu-dropdown`}
        >
          <Dropdown
            dropdownSize="full"
            items={items.map((item, index) => {
              const openSubMenu = () => {
                if (!item.subMenu?.length) return setSubMenu(null);
                setSubMenu((currentSubMenu) =>
                  currentSubMenu?.length ? null : item.subMenu!,
                );
                subMenuFloating.refs.setReference(subMenuRefs.current[index]);
                return undefined;
              };

              return {
                ...item,
                ref: (el) => {
                  subMenuRefs.current[index] = el;
                },
                onClick: item.onClick
                  ? (e) => {
                      item.onClick!(e);
                      setMenuOpen(item.keepOpen || false);
                    }
                  : openSubMenu,
                onMouseEnter: openSubMenu,
                rightIcon: item.subMenu?.length
                  ? "solidChevronRight"
                  : undefined,
              };
            })}
          />
        </div>
      )}
      {menuOpen && subMenu?.length && (
        <div
          className="z-20"
          ref={subMenuFloating.refs.setFloating}
          style={subMenuFloating.floatingStyles}
          data-testid={`${id ? `${id}-` : ""}context-menu-sub-menu-dropdown`}
        >
          <Dropdown
            className="min-w-[14rem]"
            items={subMenu.map((item) => ({
              ...item,
              onClick: (e) => {
                item.onClick?.(e);
                setSubMenu(null);
                setMenuOpen(false);
              },
            }))}
          />
        </div>
      )}
    </div>
  );
};
