import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { getCardClassNames } from "./class-names";
import { CardProps } from "./type";
import { ProgressBar } from "../progress-bar";

export const Card = ({
  children,
  loading,
  size,
  align,
  flow,
  current,
  className,
}: CardProps) => (
  <div
    className={twMerge(
      clsx(
        getCardClassNames({
          size,
          align,
          current,
          flow,
        }),
        className,
      ),
    )}
  >
    {children}
    {loading && <ProgressBar overlay className="h-[0.2rem]" />}
  </div>
);
