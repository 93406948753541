import clsx from "clsx";
import CurrencyField, {
  CurrencyInputProps as CurrencyFieldProps,
} from "react-currency-input-field";

import { loadLocale } from "@smart/itops-locale-dom";

import { BaseInput, BaseInputProps } from "./base";
import { inputClassNames } from "./class-names";
import { Label } from "../label";

export type CurrencyInputProps = Pick<
  BaseInputProps,
  "label" | "disabled" | "mandatory" | "error" | "message"
> &
  Pick<
    CurrencyFieldProps,
    | "id"
    | "name"
    | "title"
    | "type"
    | "placeholder"
    | "value"
    | "defaultValue"
    | "onChange"
    | "onBlur"
    | "onFocus"
    | "onKeyDown"
    | "readOnly"
    | "intlConfig"
    | "onValueChange"
    | "prefix"
  >;

export const CurrencyInput = (props: CurrencyInputProps) => {
  const { id, mandatory, label, error, message, placeholder, prefix, ...rest } =
    props;
  const { currencySymbol } = loadLocale();

  return (
    <Label
      text={label}
      mandatory={mandatory}
      disabled={props.disabled}
      name={id}
      error={error}
      message={message}
    >
      <BaseInput
        error={error}
        disabled={props.disabled}
        inputElement={
          <CurrencyField
            className={clsx(inputClassNames, "flex-1")}
            id={id}
            aria-invalid={error}
            aria-errormessage={error ? message : undefined}
            decimalScale={2}
            placeholder={placeholder || currencySymbol}
            prefix={prefix || currencySymbol}
            {...rest}
          />
        }
      />
    </Label>
  );
};
