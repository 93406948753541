import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

type CircleSize = "xSmall" | "small" | "medium" | "large";

const sizes: Record<CircleSize, string> = {
  xSmall: "w-[2rem] h-[2rem] border-2",
  small: "w-[4rem] h-[4rem] border-4",
  medium: "w-[8rem] h-[8rem] border-[0.6rem]",
  large: "w-[12.8rem] h-[12.8rem] border-8",
};

type ProgressCircleProps = {
  description?: string;
  size?: CircleSize;
  className?: string;
};

export const ProgressCircle = ({
  className,
  description,
  size = "large",
}: ProgressCircleProps) => (
  <div
    className={twMerge(
      clsx(
        "flex flex-col w-full h-full bg-transparent items-center justify-center z-modal gap-small",
        className,
      ),
    )}
  >
    <div
      data-testid="loading-spinner"
      className={clsx(
        "rounded-full border-solid border-neutral-100 border-t-cyan-540 animate-spin",
        sizes[size],
      )}
    />
    {description && (
      <p className="text-caption text-neutral-850 font-semibold">
        {description}
      </p>
    )}
  </div>
);
