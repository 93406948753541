import clsx from "clsx";
import { useEffect, useState } from "react";

import { specialChars } from "@smart/itops-utils-basic";

import {
  EditableInput,
  fontSizeClassNames,
  FontSizeProps,
  fontWeightClassNames,
} from "./editable-input";
import { EditableTextError } from "./error";
import { Icon } from "../icon";
import { RequiredAsterisk } from "../required-asterisk";
import { Skeleton } from "../skeleton";

export type EditableTextProps = {
  loading?: boolean;
  isMandatory?: boolean;
  text?: string | null;
  error?: string;
  placeholder?: string;
  editing?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onComplete: () => Promise<void>;
  onEditing?: (isEditing: boolean) => void;
  isEditor?: boolean;
  ignoreSubmitOnBlur?: boolean;
} & FontSizeProps;

export const EditableText = ({
  loading,
  isMandatory,
  text,
  error,
  placeholder,
  editing,
  dataTestId,
  onEditing,
  onChange,
  onComplete,
  fontSize = "ui",
  fontWeight = "normal",
  disabled = false,
  isEditor,
  ignoreSubmitOnBlur,
}: EditableTextProps) => {
  const [isEditing, setIsEditing] = useState(editing);
  const onSubmit = async () => {
    setIsEditing(false);
    await onComplete();
  };

  useEffect(() => {
    setIsEditing(editing);
  }, [editing]);

  useEffect(() => {
    if (onEditing) onEditing(!!isEditing);
  }, [isEditing]);

  if (loading)
    return (
      <div
        className={clsx(
          "max-w-full w-max relative",
          fontSizeClassNames[fontSize],
          fontWeightClassNames[fontWeight],
        )}
      >
        <div className="py-[0.4rem] max-w-full whitespace-nowrap overflow-hidden text-ellipsis">
          Loading {placeholder || "placeholder"}...
        </div>
        <div className="absolute inset-0 rounded">
          <Skeleton />
        </div>
      </div>
    );

  if (isEditing)
    return (
      <EditableInput
        text={text ?? undefined}
        dataTestId={dataTestId}
        disabled={disabled}
        onChange={onChange}
        onSubmit={onSubmit}
        fontSize={fontSize}
        fontWeight={fontWeight}
        isEditor={isEditor}
        ignoreSubmitOnBlur={ignoreSubmitOnBlur}
      />
    );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={clsx(
        "flex items-center max-w-full w-max rounded cursor-default transition",
        !disabled &&
          "hover:cursor-pointer hover:bg-neutral-50 [&_.pencil]:hover:opacity-100",
        fontSizeClassNames[fontSize],
        fontWeightClassNames[fontWeight],
      )}
      onClick={() => !disabled && setIsEditing(true)}
      aria-disabled={disabled}
      data-testid={dataTestId}
    >
      <EditableInput
        text={text ?? undefined}
        dataTestId={dataTestId}
        placeholder={placeholder}
        fontSize={fontSize}
        fontWeight={fontWeight}
        disabled
        ignoreSubmitOnBlur
      />
      {!placeholder && specialChars.nbsp}
      {isMandatory && <RequiredAsterisk />}
      {error && <EditableTextError error={error} />}

      <div className="pencil flex justify-center items-center opacity-0 w-[2.4rem] h-[2.4rem] transition">
        <Icon name="regularPencil" />
      </div>
    </div>
  );
};
