import clsx from "clsx";
import { twMerge } from "tailwind-merge";

import { getCheckboxClassNames } from "./class-names";
import { Icon } from "../icon";

export type CheckboxProps = {
  label?: string;
  value?: string;
  title?: string;
  checked?: boolean;
  indeterminate?: boolean;
  onChange?: (checked: boolean, value: string | undefined) => void;
  disabled?: boolean;
  className?: string;
  error?: boolean;
  dataTestId?: string;
};

export const Checkbox = ({
  label,
  value,
  title,
  checked,
  indeterminate = false,
  onChange,
  disabled = false,
  className,
  error = false,
  dataTestId,
}: CheckboxProps) => (
  <label
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0}
    title={title}
    className={clsx(
      "group inline-flex gap-small items-center outline-none",
      disabled ? "cursor-not-allowed" : "cursor-pointer",
      className,
    )}
  >
    <input
      className="hidden"
      type="checkbox"
      disabled={disabled}
      checked={checked}
      onChange={onChange ? (e) => onChange(e.target.checked, value) : undefined}
      data-testid={dataTestId || "checkbox-input"}
      ref={(input) => {
        if (input) input.indeterminate = indeterminate;
      }}
      onClick={(e) => e.stopPropagation()}
    />
    <div
      className={twMerge(
        getCheckboxClassNames({
          checked: checked || indeterminate,
          disabled,
          error,
        }),
      )}
    >
      {checked && !indeterminate && (
        <Icon
          name="solidCheck"
          className={clsx(
            "w-[1.2rem] h-[1.2rem] text-white",
            disabled && "text-neutral-400",
          )}
        />
      )}
      {indeterminate && (
        <Icon
          name="solidMinus"
          className={clsx(
            "w-[1.2rem] h-[1.2rem] text-white",
            disabled && "text-neutral-400",
          )}
        />
      )}
      <div
        className={twMerge(
          clsx(
            "hidden toggle-border absolute inset-[-0.4rem] border rounded-[6px] border-solid",
            !disabled && "group-focus:border-blue-560 group-focus:block",
            !disabled && error && "border-red-550 block",
          ),
        )}
      />
    </div>
    {label && (
      <span
        className={clsx("text-ui text-left", disabled && "text-neutral-400")}
      >
        {label}
      </span>
    )}
  </label>
);
