import { useEffect, useState } from "react";
import { Editor, Node, Range, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";

import {
  EditorElement,
  isEditorElementType,
} from "@smart/itops-serialisation-basic";

import {
  buttonGroupStyles,
  fieldStyles,
  inputStyles,
  labelStyles,
} from "./class-names";
import { Button } from "../../button";
import { useEditorContext } from "../hooks";

export const LinkPopup = ({ element }: { element?: EditorElement }) => {
  const editor = useSlate();
  const { setModal } = useEditorContext();
  const [text, setText] = useState(() => {
    if (element) return Node.string(element);
    if (editor.selection) return Editor.string(editor, editor.selection);
    return "";
  });
  const [url, setUrl] = useState(() => {
    if (isEditorElementType("link")(element)) return element.url;
    return "";
  });

  /**
   * Always focus on the editor when the modal is closed in case the editor has
   * event handlers on blur (e.g the EditableInput component)
   */
  useEffect(
    () => () => {
      ReactEditor.focus(editor);
    },
    [],
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const updated: EditorElement<"link"> = {
          type: "link",
          url: url.match(/^https?:\/\//) ? url : `https://${url}`,
          children: [{ text }],
        };

        if (element && isEditorElementType("link")(element)) {
          Transforms.setNodes(editor, updated, {
            match: (n) => n === element,
          });

          const path = ReactEditor.findPath(editor, element.children[0]);
          Transforms.delete(editor, { at: path });
          Transforms.insertText(editor, text, { at: path });
        } else if (editor.selection && Range.isExpanded(editor.selection)) {
          Transforms.wrapNodes(editor, updated, { split: true });
          Transforms.delete(editor, {
            at: editor.selection,
          });
          Transforms.insertText(editor, text);
        } else {
          Transforms.insertNodes(editor, updated);
        }

        setModal(undefined);
      }}
    >
      <div className="px-4 pt-6 pb-1">
        <div className={fieldStyles}>
          <label className={labelStyles}>Text</label>
          <input
            type="text"
            onChange={(e) => setText(e.currentTarget.value)}
            value={text}
            className={inputStyles}
          />
        </div>
        <div className={fieldStyles}>
          <label className={labelStyles}>Link</label>
          <input
            type="text"
            onChange={(e) => setUrl(e.currentTarget.value)}
            value={url}
            className={inputStyles}
          />
        </div>
      </div>
      <div className={buttonGroupStyles}>
        <Button
          text="Cancel"
          variant="secondarySubtle"
          onClick={() => {
            setModal(undefined);
          }}
          className="mr-4"
        />
        <Button text={element ? "Update" : "Add"} type="submit" />
      </div>
    </form>
  );
};
