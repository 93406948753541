import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { useSelected } from "slate-react";

import { ElementRendererProps } from "./paragraph";
import { Button } from "../../button";
import { useEditorContext } from "../hooks";

const linkAnchorStyles = "text-blue-560 bg-transparent cursor-pointer";

export const Link = ({
  attributes,
  children,
  element,
  readOnly,
  isLinkEnabled,
}: ElementRendererProps<"link">) => {
  const { setModal } = useEditorContext();
  const selected = useSelected();
  const open = !readOnly && selected;
  const popup = useFloating({
    placement: "right",
    open,
    middleware: [offset(5), shift({ padding: 10 }), flip()],
    whileElementsMounted: autoUpdate,
  });

  return (
    <span className="inline-block" ref={popup.refs.setReference}>
      <a
        {...attributes}
        href={isLinkEnabled ? element.url : undefined}
        target="_blank"
        rel="noopener noreferrer"
        className={linkAnchorStyles}
      >
        <span {...attributes}>{children}</span>
      </a>
      {open && (
        <span ref={popup.refs.setFloating} style={popup.floatingStyles}>
          <Button
            text="Edit Link"
            variant="secondaryDefault"
            leftIcon="solidEllipsis"
            onMouseDown={() => setModal({ option: "link", element })}
          />
        </span>
      )}
    </span>
  );
};
