import { useEffect, useState } from "react";

import { isKeyOf } from "@smart/itops-utils-basic";

import { fileTypes } from "./file-types";
import { UploadFileProps, UploadFn, UploadStatus } from "./types";
import { Button } from "../../button";
import { Icon } from "../../icon";
import { ProgressCircle } from "../../progress-circle";

export type FileFieldItemProps = {
  fileName: string;
  downloadUrl: string | undefined;
  uploadUrl: string | undefined;
  file: File | undefined;
  uploadStatus: UploadStatus;
  upload: UploadFn;
  onStatusChange: (uploadStatus: UploadStatus) => void;
  onRemove: () => void;
  uploadFileProps: UploadFileProps;
  fileSizeLimitMegaByte: number;
  maxNumOfFiles: number;
  fileIndex: number;
  fieldId: string;
};

const knownFileType = isKeyOf(fileTypes);
const fileNameToIcon = (name: string) => {
  const extension = name.split(".").reverse()[0];
  return knownFileType(extension) ? fileTypes[extension] : fileTypes.txt;
};

export const FileFieldItem = ({
  fileName,
  downloadUrl,
  uploadUrl,
  file,
  uploadStatus,
  upload,
  onStatusChange,
  onRemove,
  fileSizeLimitMegaByte,
  maxNumOfFiles,
  fileIndex,
  fieldId: id,
  uploadFileProps: {
    onProgress,
    onUploadStatusChange,
    initialise,
    getUploadStates,
  },
}: FileFieldItemProps) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const uploadStates = getUploadStates(id);
  const notUploadedStatuses: UploadStatus[] = [
    "failedToUpload",
    "exceedSizeLimit",
    "unsupportedFileType",
  ];
  const failedUploadReason: Partial<Record<UploadStatus, string>> = {
    exceedSizeLimit: `File exceeds the ${fileSizeLimitMegaByte} MB limit. Please upload a smaller file and try again.`,
    unsupportedFileType:
      "Unsupported file format. Please upload a valid file type.",
    failedToUpload: "Upload failed. Please try again.",
    fileEncrypted: "Cannot upload a password protected file.",
  };

  const extractFileDetails = () => {
    const parts = fileName.split(".");
    const extension = parts.pop();

    return {
      name: parts.join("."),
      extension: `.${extension}`,
    };
  };

  const { name: namePart, extension } = extractFileDetails();

  useEffect(() => {
    if (uploadStates.length > 0) {
      setUploading(uploadStates[fileIndex].uploading);
      setProgress(uploadStates[fileIndex].progress);
    } else {
      initialise(id, maxNumOfFiles);
    }

    if (progress === 100 && uploadStatus === "uploading") {
      onUploadStatusChange(id, fileIndex, false);
      onStatusChange("uploaded");
    }
  }, [uploadStates, progress, uploading]);

  useEffect(() => {
    if (uploadStatus === "notUploaded" && uploadUrl && file && !uploading) {
      onUploadStatusChange(id, fileIndex, true);
      onStatusChange("uploading");
      setUploading(true);

      upload({
        uploadUrl,
        file,
        onProgress: (newProgress) => {
          onProgress(id, fileIndex, newProgress);
          setProgress(newProgress);
        },
      })
        .then(() => {
          onUploadStatusChange(id, fileIndex, false);
          onStatusChange("uploaded");
          setUploading(false);
        })
        .catch((e) => {
          console.error(e);
          onUploadStatusChange(id, fileIndex, false);
          onStatusChange("failedToUpload");
          setUploading(false);
        });
    }
  }, [uploadStatus, uploadUrl]);

  return (
    <div className="bg-gray-100 flex flex-row flex-nowrap items-center gap-small m-0 p-4 rounded-small text-caption">
      <div className="flex flex-col flex-[4]">
        <div className="flex flex-row flex-nowrap items-center gap-small w-full">
          {uploading || uploadStatus === "notUploaded" ? (
            <div>
              <ProgressCircle size="xSmall" />
            </div>
          ) : (
            <div
              className="bg-contain bg-center bg-no-repeat w-11 h-11 mr-4 flex-none basis-11"
              style={{ backgroundImage: `url(${fileNameToIcon(fileName)})` }}
            />
          )}
          <p className="flex m-0 relative text-caption">
            {uploading ||
            !downloadUrl ||
            notUploadedStatuses.includes(uploadStatus) ? (
              <>
                <span className="max-w-[40rem] block whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {namePart}
                </span>
                <span>{extension}</span>
              </>
            ) : (
              <a
                href={downloadUrl}
                target="_blank"
                rel="noreferrer"
                aria-label={fileName}
              >
                <span className="max-w-[40rem] block whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {namePart}
                </span>
                <span>{extension}</span>
              </a>
            )}
          </p>
        </div>
        {uploading && (
          <div
            className="mt-2 mr-0 mb-0 ml-2 h-2 gap-2 bg-gray-300 rounded overflow-hidden"
            role="progressbar"
            aria-label="Upload Progress"
            aria-valuenow={progress}
          >
            <div
              className="h-full bg-blue-560 opacity-60 transition-all duration-200 ease-in-out"
              style={{ width: `${progress}%` }}
            />
          </div>
        )}
      </div>
      <div className="flex justify-end flex-[3] max-w-full">
        {failedUploadReason[uploadStatus] && (
          <div className="flex items-center text-red-550">
            <span>{failedUploadReason[uploadStatus]}</span>
            <div className="ml-1">
              <Icon name="regularCircleExclamation" />
            </div>
          </div>
        )}
        <Button
          variant="secondarySubtle"
          leftIcon="solidXmarkLarge"
          onClick={onRemove}
          disabled={uploadStatus === "uploading" || uploading}
          title="Remove file"
        />
      </div>
    </div>
  );
};
