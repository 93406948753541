import { autoUpdate, offset, useFloating } from "@floating-ui/react";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Editor, Transforms } from "slate";
import { Editable, RenderPlaceholderProps, Slate } from "slate-react";

import { deserialize, serialize } from "@smart/itops-serialisation-basic";
import { isTestComponent } from "@smart/itops-utils-basic";

import { Block, Leaf, TestEditable } from "../editor/elements";
import { EditorContextProvider, useEditorState } from "../editor/hooks";
import { Toolbar } from "../editor/toolbar";

export type FontSizeProps = {
  fontSize?: "h1" | "h2" | "h3" | "paragraph" | "ui" | "caption";
  fontWeight?: "normal" | "semibold" | "bold";
};

export const fontSizeClassNames = {
  h1: "text-h1",
  h2: "text-h2",
  h3: "text-h3",
  paragraph: "text-paragraph",
  ui: "text-ui",
  caption: "text-caption",
};

export const fontWeightClassNames = {
  normal: "font-normal",
  semibold: "font-semibold",
  bold: "font-bold",
};

type EditableInputProps = {
  text?: string;
  dataTestId?: string;
  disabled?: boolean;
  onChange?: (v: string) => void;
  onSubmit?: () => Promise<void>;
  isEditor?: boolean;
  placeholder?: string;
  ignoreSubmitOnBlur?: boolean;
} & FontSizeProps;

export const EditableInput = ({
  fontSize = "ui",
  fontWeight = "normal",
  text,
  dataTestId,
  disabled,
  onChange: onValueChange,
  onSubmit,
  isEditor,
  placeholder,
  ignoreSubmitOnBlur,
}: EditableInputProps) => {
  const [isTextSelected, setIsTextSelected] = useState(false);

  const { editor, initialValue, onChange, modal, setModal, onKeyDown } =
    useEditorState({
      initialTextValue: text,
      onValueChange,
      autoFocus: true,
    });

  const handleSelect = () => {
    setIsTextSelected(!!window.getSelection()?.toString().length);
  };

  const popup = useFloating({
    placement: "top-start",
    open: isTextSelected || !!modal,
    middleware: [offset(5)],
    whileElementsMounted: autoUpdate,
  });

  const updateEditorState = useCallback(() => {
    const currentContent = serialize(editor.children);
    if (currentContent !== text) {
      Transforms.deselect(editor);

      Editor.withoutNormalizing(editor, () => {
        editor.children = deserialize(text);
        editor.history = { undos: [], redos: [] };
      });

      Transforms.select(editor, Editor.end(editor, []));
    }
  }, [text, editor]);

  useEffect(() => {
    updateEditorState();
  }, [updateEditorState]);

  const renderPlaceholderText = ({ children }: RenderPlaceholderProps) => (
    <span
      data-slate-placeholder
      style={{
        opacity: 0.7,
        fontStyle: "italic",
      }}
    >
      {children}
    </span>
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onBlur={async () => {
        if (!modal && onSubmit && !isTestComponent() && !ignoreSubmitOnBlur)
          await onSubmit();
      }}
      ref={popup.refs.setReference}
      onMouseDown={() => window.getSelection()?.removeAllRanges()}
    >
      <Slate
        editor={editor}
        initialValue={initialValue}
        onValueChange={onChange}
      >
        <EditorContextProvider
          value={{
            modal,
            setModal,
          }}
        >
          {(isTextSelected || !!modal) &&
            isEditor &&
            ReactDOM.createPortal(
              <div ref={popup.refs.setFloating} style={popup.floatingStyles}>
                <Toolbar className="border-none bg-neutral-20 p-[0.5rem]" />
              </div>,
              document.querySelector('[data-testid="edit-questions"]') ||
                document.body,
            )}
          <div
            data-value={text}
            className={clsx(
              "inline-grid items-center relative max-w-full px-[0.6rem] py-[0.4rem] rounded text-inherit",
              !disabled && "bg-neutral-50",
              isEditor ? "min-w-[10rem]" : "min-w-[3rem]",
              fontSizeClassNames[fontSize],
              fontWeightClassNames[fontWeight],
            )}
          >
            <Editable
              className="outline-none"
              data-testid={`${dataTestId || "editable-input"}-editable`}
              readOnly={disabled}
              renderElement={(props) => (
                <Block {...props} readOnly={!!disabled} />
              )}
              renderLeaf={Leaf}
              renderPlaceholder={renderPlaceholderText}
              placeholder={placeholder}
              onMouseUp={handleSelect}
              onKeyUp={handleSelect}
              onKeyDown={async (e) => {
                onKeyDown(e);
                if (!onSubmit) return;
                if (
                  e.key === "Enter" &&
                  (!isEditor || (!e.shiftKey && !e.ctrlKey && !e.altKey))
                ) {
                  await onSubmit();
                }
              }}
              disabled={disabled}
              spellCheck
              autoFocus
            />
            {!!isTestComponent() && (
              <TestEditable
                placeholder={placeholder}
                disabled={disabled}
                dataTestId={`${dataTestId || "editable-input"}-textarea`}
                value={text}
                onKeyDown={async (e) => {
                  onKeyDown(e);
                  if (!onSubmit) return;
                  if (
                    e.key === "Enter" &&
                    (!isEditor || (!e.shiftKey && !e.ctrlKey && !e.altKey))
                  ) {
                    await onSubmit();
                  }
                }}
              />
            )}
          </div>
        </EditorContextProvider>
      </Slate>
    </div>
  );
};
