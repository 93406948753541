import { clsx } from "clsx";
import { useRef } from "react";

import { loadLocale } from "@smart/itops-locale-dom";

import { BaseInput } from "../input/base";
import { Label } from "../label";

const dateFormatter = {
  day: "dd",
  month: "mm",
  year: "yyyy",
};

type DateFieldInput = {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  error?: string;
};

export const DateInputField = ({
  label,
  value,
  error,
  onChange,
  onBlur,
}: DateFieldInput) => {
  const { dateFormat } = loadLocale();
  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const [yearValue, monthValue, dayValue] = `${value ?? ""}`
    .replace(/[^\d-]/g, "")
    .split("-");

  const parts = {
    day: {
      label: "Day",
      ref: dayRef,
      value: dayValue,
      minLength: 1,
      maxLength: 2,
      index: 2,
    },
    month: {
      label: "Month",
      ref: monthRef,
      value: monthValue,
      minLength: 1,
      maxLength: 2,
      index: 1,
    },
    year: {
      label: "Year",
      ref: yearRef,
      value: yearValue,
      minLength: 4,
      maxLength: 4,
      index: 0,
    },
  };

  const onChangePart = (part: keyof typeof parts, v: string) => {
    const updated = [yearValue, monthValue, dayValue];
    updated[parts[part].index] = v.replace(/[^\d+]/g, "");

    const updatedValue = updated.map((p) => p || "").join("-");
    onChange(updatedValue === "--" ? "" : updatedValue);
  };
  return (
    <Label text={label || "Date"}>
      <div
        className="flex gap-2"
        role="radiogroup"
        data-testid="date-field-input"
      >
        <input
          value={value ?? ""}
          className="absolute inset-0 h-0 w-0 overflow-hidden outline-none opacity-0 border-0"
          aria-invalid={!!error}
          readOnly
          onFocus={() => {
            const part =
              dateFormat.find((p) => !parts[p].value) || dateFormat[2];

            parts[part].ref.current?.focus();
          }}
        />
        {dateFormat.map((part, idx) => (
          <label key={part}>
            <BaseInput
              ref={parts[part].ref}
              aria-invalid={!!error}
              value={parts[part].value ?? ""}
              minLength={parts[part].minLength}
              maxLength={parts[part].maxLength}
              placeholder={dateFormatter[part]}
              onBlur={onBlur}
              onChange={(e) => {
                onChangePart(part, e.currentTarget.value);
              }}
              inputMode="numeric"
              pattern="\d+"
              className={clsx(
                "h-16",
                parts[part].minLength > 2 ? "w-[7.2rem]" : "w-[6.4rem]",
              )}
              onKeyDown={(e) => {
                const prevPart = parts[dateFormat[idx - 1]]?.ref.current;
                const nextPart = parts[dateFormat[idx + 1]]?.ref.current;

                const atStart =
                  e.currentTarget.selectionStart === 0 &&
                  e.currentTarget.selectionEnd === 0;
                const atEnd =
                  e.currentTarget.selectionStart &&
                  e.currentTarget.selectionStart >=
                    e.currentTarget.value.length;

                const goBack = () => {
                  prevPart?.focus();
                  prevPart?.setSelectionRange(
                    value.length,
                    value.length,
                    "backward",
                  );
                };
                const goNext = () => {
                  nextPart?.focus();
                  nextPart?.setSelectionRange(0, 0, "forward");
                };

                if (e.key === "Backspace" && e.currentTarget.value === "") {
                  e.preventDefault();
                  goBack();
                }
                if (
                  e.key.match(/^\d$/) &&
                  atEnd &&
                  e.currentTarget.value.length >= parts[part].maxLength
                ) {
                  goNext();
                }
                if (e.key === "/") {
                  e.preventDefault();
                  if (atEnd) {
                    goNext();
                  }
                }
                if (e.key === "ArrowLeft") {
                  if (atStart) {
                    e.preventDefault();
                    goBack();
                  }
                }
                if (e.key === "ArrowRight") {
                  if (atEnd) {
                    e.preventDefault();
                    goNext();
                  }
                }
              }}
            />
          </label>
        ))}
      </div>
    </Label>
  );
};
