import { clsx } from "clsx";

const baseStyles =
  "flex items-center justify-between w-[2rem] h-[2rem] min-w-[2rem] px-[0.3rem] border border-solid border-neutral-200 rounded-[4px] bg-neutral-20 relative";
const unselectedFocus = "group-focus:bg-white group-focus:border-neutral-200";
const unselectedHover = "group-hover:bg-white group-hover:border-neutral-200";
const unselectedActive =
  "group-active:bg-blue-160 group-active:border-blue-160";
const selectedBase = "border-blue-560 bg-blue-560";
const selectedFocus = "group-focus:bg-blue-460 group-focus:border-blue-460";
const selectedHover = "group-hover:bg-blue-460 group-hover:border-blue-460";
const selectedActive = "group-active:bg-blue-660 group-active:border-blue-660";

export const getCheckboxClassNames = ({
  checked,
  disabled,
  error,
}: {
  checked?: boolean;
  disabled: boolean;
  error: boolean;
}) => {
  const errorClassNames = checked
    ? "bg-blue-460 border-blue-460"
    : "bg-neutral-50 border-neutral-200";
  const enabledClassNames = clsx(
    unselectedFocus,
    unselectedHover,
    unselectedActive,
    checked && clsx(selectedBase, selectedFocus, selectedHover, selectedActive),
    error && errorClassNames,
  );

  return clsx(
    baseStyles,
    disabled ? "bg-black/10 border-black/0" : enabledClassNames,
  );
};
