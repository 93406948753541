import { ReactNode } from "react";

import { BaseInput, BaseInputProps } from "./base";
import { Label } from "../label";

export type TextFieldInputProps = Omit<
  BaseInputProps,
  "leftIcon" | "rightButtonIcon" | "onRightButtonClick" | "message"
> & { message?: string | ReactNode };

export const TextFieldInput = (props: TextFieldInputProps) => {
  const { mandatory, ...rest } = props;

  return (
    <Label
      text={props.label}
      mandatory={mandatory}
      disabled={props.disabled}
      name={props.id}
      error={!!props.error}
      message={props.message}
      className="flex-1"
    >
      <BaseInput
        {...rest}
        message={typeof props.message === "string" ? props.message : undefined}
      />
    </Label>
  );
};
