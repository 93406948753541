import { clsx } from "clsx";

const dropdownSizeClasses = {
  short: "max-h-[20rem]",
  medium: "max-h-[30rem]",
  long: "max-h-[40rem]",
  full: "max-h-full",
};

export const dropdownBase = (
  size: "short" | "medium" | "long" | "full" = "medium",
) =>
  clsx(
    "bg-white rounded shadow-dropdownMenu min-w-[19rem] overflow-y-auto py-[0.8rem]",
    dropdownSizeClasses[size],
  );

export const partBase =
  "flex flex-nowrap items-center gap-medium w-full cursor-pointer px-[1.6rem] py-[0.8rem] justify-between";

export const partActive =
  "active:bg-cyan-40 active:border-l-2 active:border-l-blue-660";

export const partHover = "hover:bg-neutral-50";

export const partFocus =
  "focus:bg-neutral-50 focus:border-l-2 focus:border-l-blue-660";

export const partDisabled = "opacity-40 text-neutral-400 cursor-not-allowed";

export const partForceActive = "bg-cyan-40 border-l-2 border-l-blue-660";
