import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import {
  AvatarSize,
  AvatarStyle,
  getAvatarClassNames,
  getIconClassNames,
} from "./class-names";
import { Icon, IconName } from "../icon";

type AvatarProps = {
  style?: AvatarStyle;
  size: AvatarSize;
  text?: string;
  icon?: IconName;
  disabled?: boolean;
  active?: boolean;
  bgColor?: string;
  className?: string;
};

export const Avatar = ({
  text,
  icon,
  size,
  style = "square",
  disabled,
  active,
  bgColor,
  className,
}: AvatarProps) => (
  <div
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0}
    className={twMerge(
      clsx(
        getAvatarClassNames({ size, style, disabled, active, bgColor }),
        className,
      ),
    )}
  >
    {icon ? (
      <Icon name={icon} className={getIconClassNames(size)} />
    ) : (
      <span className="truncate">{text}</span>
    )}
  </div>
);
