import {
  autoUpdate,
  flip,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
} from "@floating-ui/react";
import clsx from "clsx";
import { MouseEvent, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { twMerge } from "tailwind-merge";

import { BaseInput, BaseInputProps } from "./base";
import { Dropdown, DropdownSize } from "../dropdown";
import { Label } from "../label";
import { Tag } from "../tag";
import { Tooltip } from "../tooltip";

type Option<V extends any = any> = {
  label: string;
  value: V;
  disabled?: boolean;
};
type GroupedOption<V extends any = any> = {
  heading: string;
  items: Option<V>[];
};

export type SelectProps<V extends any = any> = {
  value: V | V[];
  onChange: (value: V | V[]) => void;
  options: Option<V>[] | Option<V>[][] | GroupedOption<V>[];
  valueComparer?: (currentValue: V, valueToCompare: V) => boolean;
  isMultiple?: boolean;
  empty?: string;
  maxItems?: number;
  className?: string;
  dropdownSize?: DropdownSize;
} & Omit<
  BaseInputProps,
  "leftIcon" | "rightButtonIcon" | "onRightButtonClick" | "value" | "onChange"
>;

export const Select = <V extends any = any>(props: SelectProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filterText, setFilterText] = useState<string | undefined>(undefined);
  const inputRef = useRef<HTMLDivElement>(null);
  const defaultComparer = (a: V, b: V) => a === b;

  const { valueComparer, mandatory, maxItems, isMultiple, ...rest } = props;

  const options = useFloating({
    placement: "bottom",
    open: showOptions,
    onOpenChange: setShowOptions,
    middleware: [shift({ padding: 10 }), flip({ fallbackPlacements: ["top"] })],
    whileElementsMounted: autoUpdate,
  });

  const optionsInteractions = useInteractions([
    useDismiss(options.context),
    useFocus(options.context),
  ]);

  useEffect(() => {
    if (!showOptions) setFilterText(undefined);
  }, [showOptions]);

  const getOptionsType = () => {
    if ((props.options[0] as GroupedOption)?.heading) return "grouped";
    if (Array.isArray(props.options[0])) return "divided";
    return "default";
  };
  const optionsType = getOptionsType();

  const compare = (o: { value: V }, value: V) =>
    (valueComparer || defaultComparer)(value, o.value);

  const getSelectedLabel = (value: V) => {
    const optionsToUse =
      optionsType === "grouped"
        ? props.options.flatMap((o) => (o as GroupedOption).items)
        : (props.options as Option[] | Option[][]).flat();
    return optionsToUse.find((o) => compare(o, value))?.label || "";
  };

  let selectedValues: V[] = [];
  if (isMultiple) {
    if (Array.isArray(props.value)) {
      selectedValues = props.value;
    } else {
      selectedValues = [];
    }
  } else {
    selectedValues = [props.value];
  }

  const removeValue = (value: V) =>
    props.onChange(selectedValues.filter((v: V) => !compare({ value }, v)));

  const mapDropdownItems = () => {
    const filterOptions = (o: Option) =>
      filterText
        ? o.label.toLowerCase().includes(filterText.toLowerCase())
        : true;
    const mapOption = (o: Option) => {
      const isExisting = isMultiple
        ? selectedValues.some((v: V) => compare(o, v))
        : compare(o, props.value);
      return {
        ...o,
        ...(isMultiple ? { checked: isExisting } : { active: isExisting }),
        disabled: o.disabled,
        onClick: (e: MouseEvent) => {
          e.preventDefault();
          if (isMultiple) {
            if (isExisting) {
              removeValue(o.value);
            } else {
              props.onChange([...selectedValues, o.value]);
            }
          } else {
            props.onChange(o.value);
            setFilterText(undefined);
            setShowOptions(false);
          }
        },
      };
    };
    const notFoundText = props.empty || "No items found";

    if (optionsType === "grouped") {
      const groupedOptions = props.options as GroupedOption<V>[];
      const items = groupedOptions
        .filter((g) => (filterText ? g.items.some(filterOptions) : true))
        .map((g) => ({
          ...g,
          items: g.items.filter(filterOptions).map(mapOption),
        }));
      return items.length
        ? items
        : [{ heading: "", items: [{ label: notFoundText }] }];
    }

    if (optionsType === "divided") {
      const dividedOptions = props.options as Option<V>[][];
      const items = dividedOptions
        .filter((ops) => (filterText ? ops.some(filterOptions) : true))
        .map((ops) => ops.filter(filterOptions).map(mapOption));
      return items.length ? items : [[{ label: notFoundText }]];
    }

    const regularOptions = props.options as Option<V>[];
    const items = regularOptions.filter(filterOptions).map(mapOption);
    return items.length ? items : [{ label: notFoundText }];
  };

  const availableOptions = (() => {
    let opts: Option<V>[];
    if (optionsType === "grouped") {
      opts = (props.options as GroupedOption<V>[]).flatMap(
        (group) => group.items,
      );
    } else if (optionsType === "divided") {
      opts = (props.options as Option<V>[][]).flat();
    } else {
      opts = props.options as Option<V>[];
    }
    if (filterText) {
      opts = opts.filter((o) =>
        o.label.toLowerCase().includes(filterText.toLowerCase()),
      );
    }
    return opts;
  })();

  const allSelected =
    availableOptions.length > 0 &&
    availableOptions.every((o) => selectedValues.some((v: V) => compare(o, v)));

  const someSelected = selectedValues.length > 0;

  const handleSelectAll = () => {
    if (allSelected || someSelected) {
      const newSelection = selectedValues.filter(
        (v: V) => !availableOptions.some((o) => compare(o, v)),
      );
      props.onChange(newSelection);
    } else {
      const newValues = availableOptions.map((o) => o.value);
      const union = Array.from(new Set([...selectedValues, ...newValues]));
      props.onChange(union);
    }
  };

  const dropdownItems = mapDropdownItems();

  const hasDisabledItem = (
    items: ReturnType<typeof mapDropdownItems>,
  ): boolean =>
    items.some((item) =>
      Array.isArray(item)
        ? hasDisabledItem(item)
        : ("disabled" in item && item.disabled) ||
          ("items" in item && hasDisabledItem(item.items)),
    );

  return (
    <div ref={inputRef} className={twMerge(clsx("relative", props.className))}>
      <Label
        text={props.label}
        mandatory={mandatory}
        disabled={props.disabled}
        name={props.id}
        error={!!props.error}
        message={props.message}
      >
        <div
          ref={options.refs.setReference}
          {...optionsInteractions.getReferenceProps()}
        >
          <BaseInput
            {...rest}
            dataTestId={rest.dataTestId || `select-${props.id}`}
            contentOverride={
              isMultiple && selectedValues.length ? (
                <div className="flex flex-wrap items-center gap-small min-w-[16.6rem] min-h-[2.5rem]">
                  {selectedValues.slice(0, maxItems).map((v: V) => {
                    const label = getSelectedLabel(v);
                    return label ? (
                      <Tag
                        key={label}
                        variant="compact"
                        text={label}
                        onClose={(e) => {
                          e.preventDefault();
                          removeValue(v);
                        }}
                      />
                    ) : null;
                  })}
                  {maxItems && selectedValues.length > maxItems && (
                    <Tooltip
                      placement="top"
                      text={selectedValues
                        .slice(maxItems)
                        .map(getSelectedLabel)
                        .join(", ")}
                    >
                      <span className="flex justify-center items-center font-semibold px-[0.5rem] border border-neutral-400 rounded-[0.4rem]">
                        +{selectedValues.length - maxItems}
                      </span>
                    </Tooltip>
                  )}
                </div>
              ) : undefined
            }
            className={clsx(isMultiple && "caret-white/0")}
            value={
              isMultiple
                ? props.value
                : filterText ?? getSelectedLabel(props.value)
            }
            onChange={(event) => setFilterText(event.target.value)}
            onRightButtonClick={() => setShowOptions((current) => !current)}
            rightButtonIcon="regularAngleDown"
          />
        </div>
        {showOptions &&
          // Render the dropdown outside its parent DOM, in the document body, to ensure consistent visibility above other UI components.
          ReactDOM.createPortal(
            <div
              className="z-modal"
              ref={options.refs.setFloating}
              style={{
                ...options.floatingStyles,
                width: `${inputRef.current?.offsetWidth}px`,
              }}
              data-testid={`${props.dataTestId || "select"}-options`}
              {...optionsInteractions.getFloatingProps()}
            >
              <Dropdown
                items={dropdownItems}
                variant={optionsType}
                searchValue={isMultiple ? filterText : undefined}
                onSearch={isMultiple ? setFilterText : undefined}
                selectAll={
                  isMultiple
                    ? {
                        onClick: handleSelectAll,
                        allSelected,
                        someSelected,
                        disabled: hasDisabledItem(dropdownItems),
                      }
                    : undefined
                }
              />
            </div>,
            document.body,
          )}
      </Label>
    </div>
  );
};
