import {
  faFloppyDisk as lightFloppyDisk,
  faFolder as lightFolder,
} from "@awesome.me/kit-81e892e0ca/icons/classic/light";
import {
  faAngleDown as regularAngleDown,
  faAngleRight as regularAngleRight,
  faAngleUp as regularAngleUp,
  faArrowsSplitUpAndLeft as regularArrowsSplitUpAndLeft,
  faArrowDown as regularArrowDown,
  faArrowLeft as regularArrowLeft,
  faArrowRight as regularArrowRight,
  faArrowsRepeat as regularArrowsRepeat,
  faArrowUp as regularArrowUp,
  faAsterisk as regularAsterisk,
  faBold as regularBold,
  faBooks as regularBooks,
  faCalendar as regularCalendar,
  faCheck as regularCheck,
  faCircleExclamation as regularCircleExclamation,
  faCircleInfo as regularCircleInfo,
  faComment as regularComment,
  faCopy as regularCopy,
  faFilePen as regularFilePen,
  faFilter as regularFilter,
  faFolder as regularFolder,
  faGear as regularGear,
  faGripDotsVertical as regularGripDotsVertical,
  faItalic as regularItalic,
  faLink as regularLink,
  faMagnifyingGlass as regularMagnifyingGlass,
  faMinus as regularMinus,
  faPaperPlaneTop as regularPlaneTop,
  faPencil as regularPencil,
  faPlus as regularPlus,
  faQrcode as regularQrcode,
  faRepeat as regularRepeat,
  faShare as regularShare,
  faSparkles as regularSparkles,
  faText as regularText,
  faTrashCan as regularTrashCan,
  faXmark as regularXmark,
  faXmarkLarge as regularXmarkLarge,
} from "@awesome.me/kit-81e892e0ca/icons/classic/regular";
import {
  faArrowsSplitUpAndLeft as solidArrowsSplitUpAndLeft,
  faBell as solidBell,
  faCalendar as solidCalendar,
  faCalendarClock as solidCalendarClock,
  faCheck as solidCheck,
  faChevronRight as solidChevronRight,
  faCircleCheck as solidCircleCheck,
  faCircleExclamation as solidCircleExclamation,
  faCircleInfo as solidCircleInfo,
  faComment as solidComment,
  faContactCard as solidContactCard,
  faCopy as solidCopy,
  faCreditCard as solidCreditCard,
  faDiamondExclamation as solidDiamondExclamation,
  faDollarSign as solidDollarSign,
  faEllipsis as solidEllipsis,
  faEnvelope as solidEnvelope,
  faFileArrowUp as solidFileArrowUp,
  faFloppyDisk as solidFloppyDisk,
  faFolder as solidFolder,
  faGear as solidGear,
  faGripDotsVertical as solidGripDotsVertical,
  faHashtag as solidHashtag,
  faInputText as solidInputText,
  faList as solidList,
  faListRadio as solidListRadio,
  faLocationDot as solidLocationDot,
  faMemo as solidMemo,
  faMinus as solidMinus,
  faNetworkWired as solidNetworkWired,
  faObjectGroup as solidObjectGroup,
  faPaperPlaneTop as solidPaperPlaneTop,
  faPencil as solidPencil,
  faPenToSquare as solidPenToSquare,
  faPhone as solidPhone,
  faPlus as solidPlus,
  faQrcode as solidQrcode,
  faSave as solidSave,
  faShare as solidShare,
  faShieldCheck as solidShieldCheck,
  faSquareA as solidSquareA,
  faSquareUp as solidSquareUp,
  faTrashCan as solidTrashCan,
  faTriangleExclamation as solidTriangleExclamation,
  faXmark as solidXmark,
  faXmarkLarge as solidXmarkLarge,
} from "@awesome.me/kit-81e892e0ca/icons/classic/solid";

export const availableIcons = {
  lightFloppyDisk,
  lightFolder,
  regularAngleDown,
  regularAngleRight,
  regularAngleUp,
  regularArrowsSplitUpAndLeft,
  regularArrowDown,
  regularArrowLeft,
  regularArrowRight,
  regularArrowsRepeat,
  regularArrowUp,
  regularAsterisk,
  regularBold,
  regularBooks,
  regularCalendar,
  regularCheck,
  regularCircleExclamation,
  regularCircleInfo,
  regularComment,
  regularCopy,
  regularFilePen,
  regularFilter,
  regularFolder,
  regularGear,
  regularGripDotsVertical,
  regularItalic,
  regularLink,
  regularMagnifyingGlass,
  regularMinus,
  regularPencil,
  regularPlaneTop,
  regularPlus,
  regularQrcode,
  regularRepeat,
  regularShare,
  regularSparkles,
  regularText,
  regularTrashCan,
  regularXmark,
  regularXmarkLarge,
  solidArrowsSplitUpAndLeft,
  solidBell,
  solidCalendar,
  solidCalendarClock,
  solidCheck,
  solidChevronRight,
  solidCircleCheck,
  solidCircleExclamation,
  solidCircleInfo,
  solidComment,
  solidContactCard,
  solidCopy,
  solidCreditCard,
  solidDiamondExclamation,
  solidDollarSign,
  solidEllipsis,
  solidEnvelope,
  solidFileArrowUp,
  solidFloppyDisk,
  solidFolder,
  solidGear,
  solidGripDotsVertical,
  solidHashtag,
  solidInputText,
  solidList,
  solidListRadio,
  solidLocationDot,
  solidMemo,
  solidMinus,
  solidNetworkWired,
  solidObjectGroup,
  solidPaperPlaneTop,
  solidPencil,
  solidPenToSquare,
  solidPhone,
  solidPlus,
  solidQrcode,
  solidSave,
  solidShare,
  solidShieldCheck,
  solidSquareA,
  solidSquareUp,
  solidTrashCan,
  solidTriangleExclamation,
  solidXmark,
  solidXmarkLarge,
};

export type IconName = keyof typeof availableIcons | "archie";
